<template>
    <div>
        <s-form>
            <div class="row">
                <div class="col-12 mb-4">
                    <h2 class="head font-poppins-semibold mt-3 text-primary">Semen Storage Diagram</h2>
                </div>
                <div class="col-lg-2">
                    <validated-select v-model="model.storage_type" :options="typeOptions" class="c-input-select"
                                      label="Type" @input="StorageOptions"
                    ></validated-select>
                </div>
                <div class="col-lg-2">
                    <validated-select v-model="model.storage_name" class="c-input-select"
                                      :options="storageNameOptions" @input="clearData"
                                      :label="setNoLabel"></validated-select>
                </div>
            </div>
            <div class="row mt-4" v-if="model.storage_type && model.storage_name">
                <!--                <transition-fade>-->
                <div class="col-lg-5">
                    <jumbo-doughnut-apex-chart :storage-name="getLabelByValue(model.storage_name)"
                                               v-if="model.storage_type === 'JUMBO'"
                                               :colors-array="colorsArray"
                                               @item-selected="chartClickEventHandler"/>
                    <omr-doughnut-apex-chart :storage-name="getLabelByValue(model.storage_name)"
                                             v-if="model.storage_type === 'OMR'"
                                             @event-test="chartClickEventHandler"/>
                </div>
                <div class="col-lg-4 fl-y mt-6" key="omr-pyramid" v-if="model.storage_type === 'OMR'">
                    <data-pyramid :chamber="chamberNumber" v-if="chamberNumber"
                                  :filled-data="normalizedData"
                                  @item="loadCanister"></data-pyramid>
                </div>
                <div class="col-lg-3 fl-y fl-j-c">

                    <div>
                        <div class="card text-center" v-if="jumboLoading">
                            <loading-horizontal dot-background="rgb( 57,84,164 )"/>
                        </div>
                        <div class="card text-center text-primary p-0"
                             v-else-if="jumboTableData && !jumboLoading &&
                                jumboTableData.length === 0 &&
                                chamberNumber &&
                                model.storage_type === 'JUMBO'">
                            <div class="fl-te-c pt-2 px-3 py-2 bg-primary">
                                <p class="text-white w-100p m-0 text-center font-poppins-semibold">Chamber {{ chamberNumber }}</p>
                            </div>
                            <div class="p-3">
                                <h5 class="mt-3 mb-3"> - Empty - </h5>
                            </div>
                        </div>
                        <div v-else>
                            <transition-fade>
                                <chart-table key="jumbo-table" v-if="!jumboLoading && chamberNumber && model.storage_type === 'JUMBO'" :fields="tableFields" :data="jumboTableData">
                                    <template #heading>
                                        <p class="text-white w-100p m-0 text-center font-poppins-semibold">Chamber {{ chamberNumber }}</p>
                                    </template>
                                    <template #chamber="{rowData}">
                                        <div class="py-1 text-primary font-poppins-extra-bold fs-lg-1">{{ rowData.chamber }}</div>
                                    </template>
                                    <template #data="{rowData}">

                                        <div class="py-1 font-poppins-medium text-3">
                                            <span>Large : </span><span class="ml-1"> {{ rowData.large.title }}</span> <span class="ml-3">{{ rowData.large.dose }}</span>
                                        </div>
                                        <div class="py-1 font-poppins-medium text-3">
                                            <span>Small : </span><span class="ml-1"> {{ rowData.small.title }}</span> <span class="ml-3">{{ rowData.small.dose }}</span>
                                        </div>

                                    </template>
                                </chart-table>
                            </transition-fade>
                        </div>
                    </div>
                    <div>
                        <div class="card text-center" v-if="omrLoading">
                            <loading-horizontal dot-background="rgb( 57,84,164 )"/>
                        </div>
                        <div class="card text-center text-primary p-0"
                             v-if="omrTableData && !omrLoading &&
                                omrTableData.length === 0 &&
                                chamberNumber && canisterNumber &&
                                model.storage_type === 'OMR'">
                            <div class="fl-te-c pt-2 px-3 py-2 bg-primary">
                                <p class="text-white w-100p m-0 py-1 text-center" v-html="getName(chamberNumber, canisterNumber)"></p>
                            </div>
                            <div class="p-3">
                                <h5 class="mt-3 mb-3"> - Empty - </h5>
                            </div>
                        </div>
                        <div v-else>
                            <transition-fade>
                                <chart-table key="omr-table"
                                             v-if="!omrLoading && chamberNumber&& canisterNumber && model.storage_type === 'OMR'"
                                             :fields="tableFields" :data="omrTableData">
                                    <template #heading>
                                        <p class="text-white w-100p m-0 py-1 text-center" v-html="getName(chamberNumber, canisterNumber)"></p>
                                    </template>
                                    <template #chamber="{rowData}">
                                        <div class="py-1 text-primary font-poppins-extra-bold fs-lg-1">{{ rowData.chamber }}</div>
                                    </template>
                                    <template #data="{rowData}">
                                        <div class="py-1 font-poppins-medium text-3">
                                            <span>Large : </span><span class="ml-1"> {{ rowData.large.title }}</span> <span class="ml-3">{{ rowData.large.dose }}</span>
                                        </div>
                                        <div class="py-1 font-poppins-medium text-3">
                                            <span>Small : </span><span class="ml-1"> {{ rowData.small.title }}</span> <span class="ml-3">{{ rowData.small.dose }}</span>
                                        </div>
                                    </template>
                                </chart-table>
                            </transition-fade>
                        </div>
                    </div>

                </div>
                <!--                </transition-fade>-->
            </div>
            <div class="row mt-3 ml-lg-6" v-if="model.storage_name && model.storage_type === 'OMR'">
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-2">
                            <chart-labels text="A - vaccant" box-bg-color="8"></chart-labels>
                        </div>
                        <div class="col-lg-2">
                            <chart-labels text="A - filled" box-bg-color="9" class=""></chart-labels>

                        </div>
                        <div class="col-lg-2">
                            <chart-labels text="Upto B - filled" box-bg-color="5" class=""></chart-labels>

                        </div>
                        <div class="col-lg-2">
                            <chart-labels text="Upto C - filled" box-bg-color="11" class=""></chart-labels>

                        </div>
                        <div class="col-lg-2">
                            <chart-labels text="Upto D - filled" box-bg-color="12" class=""></chart-labels>

                        </div>
                        <div class="col-lg-2">
                            <chart-labels text="Upto E - Filled" box-bg-color="10" class=""></chart-labels>

                        </div>
                    </div>
                </div>

            </div>
        </s-form>
    </div>
</template>

<script>
import ChartLabels from '@/views/bulls/semen-storage-diagram/components/chart-components/ChartLabels';
// import DetailsTableOne from '@/views/bulls/semen-storage-diagram/components/chart-components/DetailsTableOne';
// import DetailsTableTwo from '@/views/bulls/semen-storage-diagram/components/chart-components/DetailsTableTwo';
import ChartTable from '../../../components/details-page/chart-table';
import JumboDoughnutApexChart from './components/chart-components/JumboDoughnutApexChart';
import OmrDoughnutApexChart from './components/chart-components/OmrDoughnutApexChart';
import DataPyramid from './components/chart-components/data-Pyramid';
import axios from 'secure-axios';
import masterURLs from '../../../data/masterURLs';
import urls from '../../../data/urls';

export default {
    name: 'SemenStorageDiagramPage',
    components: {
        DataPyramid,
        OmrDoughnutApexChart,
        JumboDoughnutApexChart,
        ChartLabels,
        ChartTable
    },
    data () {
        return {
            bgColor1: '#f7f7f7',
            textColor1: 'blue',
            bgColor2: '#f7f7f7',
            textColor2: 'black',
            storageNameOptionsURL: masterURLs.master.storageType.vueSelect,
            typeOptions: [
                {
                    label: 'OMR',
                    value: 'OMR'
                },
                {
                    label: 'JUMBO',
                    value: 'JUMBO'
                }
            ],
            model: {
                storage_type: '',
                storage_name: '',
                chamber: ''
            },
            CountModel: {
                storage_type: '',
                storage_name: '',
                chamberNumber: ''
            },
            normalizedData: [],
            colorsArray: [],
            countData: [],
            storageNameOptions: [],
            chamberNumber: '',
            canisterNumber: '',
            result: [],
            jumboLoading: false,
            omrLoading: false,
            countLoading: false,
            tableFields: [
                {
                    name: '__slot:chamber',
                    title: '',
                    titleClass: 'text-6 fs-lg-0 font-poppins-bold b-unset d-none',
                    dataClass: 'text-2 my-3  mb-3 w-5r fs-lg-0 h-80p mt-n3 bt-1 b-dark'
                },
                {
                    name: '__slot:data',
                    title: '',
                    titleClass: 'text-6 fs-lg-0 font-poppins-bold b-unset d-none',
                    dataClass: 'text-2 my-3 w-20r  fs-lg-0 h-80p mt-n3 bt-1 b-dark'
                }
            ],
            jumboTableData: [],
            omrTableData: [],
            pattern: ['A1', 'B1', 'B2', 'C1', 'C2', 'C3', 'D1', 'D2', 'D3', 'D4', 'E1', 'E2', 'E3', 'E4', 'E5', 'F1', 'F2', 'F3', 'F4', 'F5', 'F6']

        };
    },
    computed: {
        setNoLabel () {
            if (this.model.storage_type === 'JUMBO') {
                return 'JUMBO NO';
            } else if (this.model.storage_type === 'OMR') {
                return 'OMR NO';
            } else {
                return 'NO';
            }
        }
    },
    watch: {
        'model.storage_type': 'a',
        'model.storage_name': 'b',
        'model.chamber': 'checkLoadData',
        canisterNumber: 'checkLoadData',
        chamberNumber: 'c',
        'CountModel.chamberNumber': 'checkCountData',
        'CountModel.storage_type': 'checkCountData',
        'CountModel.storage_name': 'checkCountData',
        normalizedData: 'renderKeyValuePairs'
    },
    methods: {
        chartClickEventHandler (dataPointIndex) {
            // console.log('dataPointIndex: ', dataPointIndex);
            this.chamberNumber = dataPointIndex;
            this.model.chamber = dataPointIndex;
            // console.log('chartClickEventHandler Test', dataPointIndex);
        },
        async StorageOptions () {
            this.model.storage_name = '';
            const response = await axios.get(this.storageNameOptionsURL + '?storage_type=' + this.model.storage_type);
            this.storageNameOptions = response.data.data;
        },
        getLabelByValue (value) {
            const dataArray = this.storageNameOptions;
            const matchedItem = dataArray.find(item => item.value === value);
            return matchedItem ? matchedItem.label : null;
        },
        clearData () {
            this.chamberNumber = '';
        },
        checkCountData () {
            if (this.CountModel.storage_type === 'JUMBO' && !this.CountModel.chamberNumber) {
                this.checkCountJumboData();
            } else {
                this.checkCountOMRData();
            }
        },
        checkCountJumboData () {
            console.log('I am the Problem: checkCountJumboData');
            if (this.CountModel.storage_type === 'JUMBO' && this.CountModel.storage_name !== '') {
                this.loadCountData();
            }
        },
        checkCountOMRData () {
            console.log('I am the Problem: checkCountOMRData');
            if (this.CountModel.storage_type === 'OMR' && this.CountModel.storage_name !== '' && this.chamberNumber) {
                this.loadCountData();
            }
        },
        a () {
            this.CountModel.storage_type = this.model.storage_type;
            this.checkLoadData();
        },
        b () {
            this.CountModel.storage_name = this.model.storage_name;
            this.checkLoadData();
        },
        c () {
            this.CountModel.chamberNumber = this.chamberNumber;
            this.checkLoadData();
        },
        checkLoadData () {
            if (this.model.storage_type === 'JUMBO' && this.model.storage_name !== '' && this.model.chamber !== '') {
                this.loadJumboData();
            }
            if (this.model.storage_type === 'OMR' &&
                this.model.storage_name !== '' &&
                this.model.chamber !== '' &&
                this.canisterNumber !== ''
            ) {
                this.loadOMRData();
            }
        },
        loadCanister (canister) {
            // console.log('canister : ', canister);
            this.canisterNumber = canister;
        },
        getName (chamber, canister) {
            const OMRName = this.model.storage_name;
            const fullName = this.getLabelByValue(OMRName) + chamber + canister;
            return fullName;
        },
        async loadCountData () {
            try {
                this.countLoading = true;
                let response = '';
                if (this.CountModel.storage_type === 'JUMBO') {
                    response = await axios.form(urls.semen_storage.getCountData, {

                        storage_type: this.CountModel.storage_type,
                        storage_name: this.CountModel.storage_name
                    });
                } else if (this.CountModel.storage_type === 'OMR') {
                    response = await axios.form(urls.semen_storage.getOMRCountData, {
                        storage_type: this.CountModel.storage_type,
                        storage_name: this.CountModel.storage_name,
                        chamber: this.chamberNumber

                    });
                }
                const result = response.data;
                console.log(result);
                if (result.success) {
                    this.countData = [];
                    if (this.CountModel.storage_type === 'OMR') {
                        console.log('CountModel: OMR: ', result.data[0]);
                        this.countData.push(result.data[0]);
                        console.log('this.countData', this.countData);
                    } else {
                        this.countData = result.data;
                    }

                    // this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.countLoading = false;

                    this.checkNormalization();
                } else {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            let errorMessage = '';
                            if (key === '__all__') {
                                errorMessage = `${errors[key]}`;
                            } else {
                                errorMessage = `${key}:  ${errors[key]}`;
                            }
                            this.$notify('Warning', errorMessage, { type: 'warning' });
                        }
                    }
                    this.countLoading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.countLoading = false;
            }
        },
        checkNormalization () {
            if (this.model.storage_type === 'OMR') {
                this.updateInputValues();
            }
            if (this.model.storage_type === 'JUMBO') {
                this.normalizeDataLoad();
            }
        },
        normalizeDataLoad () {
            const keysToCheck = Array.from({ length: 11 }, (_, i) => (i + 1).toString());

            this.normalizedData = this.countData.map((item) => {
                const normalizedItem = {};

                keysToCheck.forEach((key) => {
                    normalizedItem[key] = item[key] || 0;
                });

                return normalizedItem;
            });
        },
        updateInputValues () {
            if (this.countData.length === 0) {
                this.normalizedData = this.pattern.map(key => ({ [key]: 'X' }));
            } else {
                this.normalizedData = this.countData.map((inputObject) => {
                    const updatedObject = { ...inputObject };
                    this.pattern.forEach((key) => {
                        if (!updatedObject[key]) {
                            this.$set(updatedObject, key, 'X');
                        }
                    });
                    return updatedObject;
                });
            }
        },
        renderKeyValuePairs () {
            this.colorsArray = [];

            for (let i = 0; i < this.normalizedData.length; i++) {
                const currentObject = this.normalizedData[i];
                for (const key in currentObject) {
                    const value = currentObject[key];
                    if (value >= 1 && value <= 3) {
                        this.colorsArray.push('#f4c431');
                    } else if (value === 4) {
                        this.colorsArray.push('#e35151');
                    } else if (value === 0) {
                        this.colorsArray.push('#00a86b');
                    }
                }
            }
        },
        async loadJumboData () {
            try {
                this.jumboLoading = true;
                const response = await axios.form(urls.semen_storage.getData, this.model);
                const result = response.data;
                if (result.success) {
                    // console.log('Response: ', result.data);
                    this.jumboTableData = result.data.jumboTableData;
                    // this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.jumboLoading = false;
                } else {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            let errorMessage = '';
                            if (key === '__all__') {
                                errorMessage = `${errors[key]}`;
                            } else {
                                errorMessage = `${key}:  ${errors[key]}`;
                            }
                            this.$notify('Warning', errorMessage, { type: 'warning' });
                        }
                    }
                    this.jumboLoading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.jumboLoading = false;
            }
        },
        async loadOMRData () {
            try {
                this.omrLoading = true;
                const response = await axios.form(urls.semen_storage.getOMRData, {
                    storage_type: this.model.storage_type,
                    storage_name: this.model.storage_name,
                    chamber: this.model.chamber,
                    sub_chamber: this.canisterNumber

                });
                const result = response.data;
                if (result.success) {
                    // console.log('Response: ', result.data);
                    this.omrTableData = result.data.OmrTableData;
                    // this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.omrLoading = false;
                } else {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            let errorMessage = '';
                            if (key === '__all__') {
                                errorMessage = `${errors[key]}`;
                            } else {
                                errorMessage = `${key}:  ${errors[key]}`;
                            }
                            this.$notify('Warning', errorMessage, { type: 'warning' });
                        }
                    }
                    this.omrLoading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.omrLoading = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
